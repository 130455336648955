import i01_A from '../img/01_A.jpg'
import i02 from '../img/02.jpg'
import i03 from '../img/03.jpg'
import i04 from '../img/04.jpg'
import i05 from '../img/05.jpg'
import i06 from '../img/06.jpg'
import i07 from '../img/07.jpg'
import i08 from '../img/08.jpg'
import i09 from '../img/09.jpg'

import i10_A from '../img/10_A.jpg'
import i11 from '../img/11.jpg'
import i12 from '../img/12.jpg'
import i13 from '../img/13.jpg'
import i14 from '../img/14.jpg'
import i15 from '../img/15.jpg'
import i16 from '../img/16.jpg'
import i17 from '../img/17.jpg'

import i18_A from '../img/18_A.jpg'
import i19 from '../img/19.jpg'
import i20 from '../img/20.jpg'
import i21 from '../img/21.jpg'
import i22 from '../img/22.jpg'
import i23 from '../img/23.jpg'

import i24_A from '../img/24_A.jpg'
import i25 from '../img/25.jpg'
import i26 from '../img/26.jpg'
import i27 from '../img/27.jpg'
import i28 from '../img/28.jpg'
import i29 from '../img/29.jpg'
import i30 from '../img/30.jpg'

import i31_A from '../img/31_A.jpg'
import i32 from '../img/32.jpg'
import i33 from '../img/33.jpg'
import i34 from '../img/34.jpg'
import i35 from '../img/35.jpg'
import i36 from '../img/36.jpg'

import i37_A from '../img/37_A.jpg'
import i38 from '../img/38.jpg'
import i39 from '../img/39.jpg'
import i40 from '../img/40.jpg'
import i41 from '../img/41.jpg'
import i42 from '../img/42.jpg'
import i43 from '../img/43.jpg'
import i44 from '../img/44.jpg'
import i45 from '../img/45.jpg'
import i46 from '../img/46.jpg'
import i47 from '../img/47.jpg'
import i48 from '../img/48.jpg'
import i49 from '../img/49.jpg'
import i50 from '../img/50.jpg'
import i51 from '../img/51.jpg'
import i52 from '../img/52.jpg'
import i53 from '../img/53.jpg'

export const img = {
  lundegard: { i01_A, i02, i03, i04, i05, i06, i07, i08, i09, },
  ecosond: { i10_A, i11, i12, i13, i14, i15, i16, i17, },
  revma: { i18_A, i19, i20, i21, i22, i23, },
  sklizeno: { i24_A, i25, i26, i27, i28, i29, i30, },
  hello: { i31_A, i32, i33, i34, i35, i36, },
  book: { i37_A, i38, i39, i40, i41, i42, i43, },
  rest: { i44, i45, i46, i47, i48, i49, i50, i51, i52, i53, },
}